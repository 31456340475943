import { deserialize } from './deserialize.js';
import { serialize } from './serialize.js';

/**
 * @typedef {Array<string,any>} Record a type representation
 */

/**
 * Returns an array of serialized Records.
 * @param {any} any a serializable value.
 * @param {{transfer?: any[], json?: boolean, lossy?: boolean}?} options an object with
 * a transfer option (ignored when polyfilled) and/or non standard fields that
 * fallback to the polyfill if present.
 * @returns {Record[]}
 */
export default typeof structuredClone === "function" ? /* c8 ignore start */
(any, options) => options && ('json' in options || 'lossy' in options) ? deserialize(serialize(any, options)) : structuredClone(any) : (any, options) => deserialize(serialize(any, options));
/* c8 ignore stop */

export { deserialize, serialize };